<template>
    <div>
        <div class="page-filter-box">
            <el-row :gutter="20">
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                    <el-input placeholder="请输入名称或电话" v-model="params.keyword" size="small"></el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4" class="page-filter-btns">
                    <el-button
                        type="primary"
                        icon="el-icon-search"
                        @click="search"
                        size="small"
                        >查询</el-button
                    >
                    <el-button
                        type="default"
                        icon="el-icon-refresh"
                        @click="reset"
                        size="small"
                        >重置</el-button
                    >
                </el-col>
            </el-row>
        </div>
        <div class="page-list-box">
            <el-table
                :data="dataList"
                border
                style="width: 100%"
                v-loading="listLoading"
                size="mini"
                stripe
            >
                <el-table-column prop="customer" label="客户名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="name" label="送礼人" show-overflow-tooltip></el-table-column>
                <el-table-column prop="telephone" label="送礼人电话" min-width="120"></el-table-column>
                <el-table-column prop="status" label="状态"></el-table-column>
                <el-table-column prop="money" label="金额（元）"></el-table-column>
                <el-table-column prop="payType" label="支付方式"></el-table-column>
                <el-table-column prop="payTime" label="送礼时间" min-width="150"></el-table-column>
                <el-table-column prop="transNo" label="交易号" min-width="150"></el-table-column>
            </el-table>
        </div>
        <div class="page-pagination-box clearfix">
            <el-pagination
                v-if="data"
                background
                class="fr"
                @size-change="handleSizeChange"
                @current-change="getDataList"
                :current-page.sync="params.pn"
                :page-sizes="[15,30,50]"
                :page-size.sync="params.ps"
                layout="total, sizes, prev, pager, next"
                :total="data.totalCount || 0">
            </el-pagination>
        </div>
        <!-- <edit ref="userEdit" @onUpdate="getDataList"></edit> -->
    </div>
</template>
<script>
// import Edit from './components/edit';

const defaultParams = {
    pn:1,
    keyword: "",
    ps:15,
}
export default {
    // components:{Edit},
    data() {
        return {
            data: null,
            dataList: null,
            params: {
                ...defaultParams
            },
            listLoading: true,
        };
    },
    computed:{
        // status(){
        //     return new Map([
        //         [-1,'删除'],
        //         [1,'正常'],
        //         [0,'禁用'],
        //     ])
        // }
    },
    mounted() {
        this.getDataList();
    },
    methods: {
        getDataList() {
            this.listLoading = true;
            this.$request({
                url: "/api/ht/lijin/list",
                params: this.params,
            })
                .then((res) => {
                    const { state, errmsg, result } = res.data;
                    if (state && result) {
                        this.data = result;
                        this.dataList = result.list || [];
                    } else {
                        this.$message.error(errmsg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        //选择pageSize
        handleSizeChange(e) {
            this.params.pn = 1;
            this.params.ps = e;
            this.getDataList();
        },
        //搜索
        search() {
            this.params.pn = 1;
            this.getDataList();
        },
        //重置搜索
        reset() {
            this.params = {
                ...defaultParams,
                ps: this.params.ps,
            };
            this.getDataList();
        },
    },
};
</script>